/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from "react"
import "./src/css/globals.css"
import "./src/css/tailwind.scss"
// You can delete this file if you're not using it

export const wrapRootElement = ({ element }) => {
  return <>{element}</>
}
